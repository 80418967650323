import {
  DocumentListFilterMap,
  DocumentListSearchType,
} from "source/types/document-list/documentList.types";
import { ReduxState } from ".";
import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { SelectionState } from "./addDocs";
import { omit } from "lodash";

export type DocumentListReduxType = {
  numSearchTotalRows: number | null; // Total search count
  isSelectionLoading: boolean;
  forceGridRefresh: boolean; // When you upload into sidebar
  activeDocumentListId: string | null;
  isGridDragging: boolean;
  rootFolderId: string | null;
  isInitialized: boolean;
  searchType: DocumentListSearchType | null; // Semantic vs keyword
  search: string | null; // local search, used while typing
  activeSearch: string | null; // global search, used by endpoint
  activeMetadataFilters: DocumentListFilterMap;
  preserveState: boolean;
};

const initialState: DocumentListReduxType = {
  isSelectionLoading: false,
  forceGridRefresh: false,
  activeDocumentListId: null,
  isGridDragging: false,
  rootFolderId: null,
  numSearchTotalRows: null,
  isInitialized: false,
  searchType: null,
  search: null,
  activeSearch: null,
  activeMetadataFilters: {},
  preserveState: false,
};

export const getDocListsSelectionState = (state): SelectionState =>
  state.addDocs.documentListSelectedDocIds;

export const getAllDocListsSelectedIds = createSelector(
  getDocListsSelectionState,
  (docListsState: Record<string, string[]>): string[] => {
    return Object.values(docListsState).flat();
  }
);

export const getNumRowsToAdd = (state: ReduxState) => {
  const docListIds = getAllDocListsSelectedIds(state);
  return docListIds.length;
};

export const getIsSelectionStateLoading = (state: ReduxState) =>
  state.documentList.isSelectionLoading;

export const getForceGridRefresh = (state: ReduxState) =>
  state.documentList.forceGridRefresh;

export const getActiveDocumentListId = (state: ReduxState) =>
  state.documentList.activeDocumentListId;

export const getIsGridDragging = (state: ReduxState) =>
  state.documentList.isGridDragging;

export const getRootFolderId = (state: ReduxState) =>
  state.documentList.rootFolderId;

export const getSearch = (state: ReduxState) => state.documentList.search;

export const getNumSearchTotalRows = (state: ReduxState) =>
  state.documentList.numSearchTotalRows;

export const getIsInitialized = (state: ReduxState) =>
  state.documentList.isInitialized;

export const getSearchType = (state: ReduxState) =>
  state.documentList.searchType;

export const getActiveSearch = (state: ReduxState) =>
  state.documentList.activeSearch;

export const getActiveMetadataFilters = (state: ReduxState) =>
  state.documentList.activeMetadataFilters;

export const getPreserveState = (state: ReduxState) =>
  state.documentList.preserveState;

const documentListSlice = createSlice({
  name: "documentList",
  initialState,
  reducers: {
    setIsSelectionLoading: (
      state: DocumentListReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isSelectionLoading = action.payload;
      return state;
    },
    setNumSearchTotalRows: (
      state: DocumentListReduxType,
      action: PayloadAction<number | null>
    ) => {
      state.numSearchTotalRows = action.payload;
      return state;
    },
    setForceGridRefresh: (
      state: DocumentListReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.forceGridRefresh = action.payload;
      return state;
    },
    setIsGridDragging: (
      state: DocumentListReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isGridDragging = action.payload;
      return state;
    },
    resetAll: (state: DocumentListReduxType) => {
      state = {
        ...initialState,
        preserveState: state.preserveState,
        activeDocumentListId: state.activeDocumentListId,
      };
      return state;
    },
    setActiveDocumentListId: (
      state: DocumentListReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.activeDocumentListId = action.payload;
      return state;
    },
    setRootFolderId: (
      state: DocumentListReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.rootFolderId = action.payload;
      return state;
    },
    setSearch: (
      state: DocumentListReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.search = action.payload;
      return state;
    },
    setIsInitialized: (
      state: DocumentListReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.isInitialized = action.payload;
      return state;
    },
    setSearchType: (
      state: DocumentListReduxType,
      action: PayloadAction<DocumentListSearchType | null>
    ) => {
      state.searchType = action.payload;
      return state;
    },
    setActiveSearch: (
      state: DocumentListReduxType,
      action: PayloadAction<string | null>
    ) => {
      state.activeSearch = action.payload;
      return state;
    },
    setActiveMetadataFilters: (
      state: DocumentListReduxType,
      action: PayloadAction<DocumentListFilterMap>
    ) => {
      state.activeMetadataFilters = action.payload;
      return state;
    },
    setPreserveState: (
      state: DocumentListReduxType,
      action: PayloadAction<boolean>
    ) => {
      state.preserveState = action.payload;
      return state;
    },
  },
});

export const {
  setForceGridRefresh,
  setActiveDocumentListId,
  setIsGridDragging,
  setIsSelectionLoading,
  resetAll,
  setRootFolderId,
  setSearch,
  setNumSearchTotalRows,
  setIsInitialized,
  setSearchType,
  setActiveSearch,
  setActiveMetadataFilters,
  setPreserveState,
} = documentListSlice.actions;
export const documentListReducer = documentListSlice.reducer;
